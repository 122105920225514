import url from '@/resources/_url'

const head = {
  title: {
    inner: 'เมนูของ TrueCoffee GO'
  },
  meta: [
    {
      id: 'description',
      name: 'description',
      content: 'TrueCoffee GO เครื่องดื่มที่ตอบโจทย์ไลฟ์สไตล์คนรุ่นใหม่อย่างเต็มรูปแบบ พร้อมเสิร์ฟความอร่อย หลากหลายเมนู SIGNATURE TRUEGO COFFEE หรือ TEA และเบเกอรี่สดใหม่'
    },
    {
      id: 'og:url',
      name: 'og:url',
      content: `${url.mainUrl}/menu`
    },
    {
      id: 'og:title',
      name: 'og:title',
      content: 'เมนูของ TrueCoffee GO'
    },
    {
      id: 'og:description',
      name: 'og:description',
      content: 'TrueCoffee GO เครื่องดื่มที่ตอบโจทย์ไลฟ์สไตล์คนรุ่นใหม่อย่างเต็มรูปแบบ พร้อมเสิร์ฟความอร่อย หลากหลายเมนู SIGNATURE TRUEGO COFFEE หรือ TEA และเบเกอรี่สดใหม่'
    },
    {
      id: 'og:image',
      name: 'og:image',
      content: '/meta-image.png'
    }
  ],
  link: [
    {
      id: 'canonical',
      rel: 'canonical',
      href: `${url.mainUrl}/menu`
    }
  ]
}

export default head
