<template>
  <!-- v-if="hadCate && hadFilter && hadMenu" -->
  <div class="container-fluid menu">
    <Loading v-if="menus.length === 0 || menus === null" />
    <div v-else class="row">
      <div class="col-md-3 col-lg-2 col-filter">
        <div class="filter-left">
          <div class="category">
            <label
              v-for="(category, i) in categories"
              :key="category.id" >

              <input
                type="radio"
                v-model="valueCategory"
                :value="category">
              <div class="text">
                <span :id="categoriesId[i]">{{ category.name.toUpperCase() }}</span>
                <div
                  class="selected-line" >
                </div>
              </div>
            </label>
          </div>

          <hr>

          <div class="filter">
            <div class="option">
              <span>Filter</span>
              <!-- <button @click="clickReset">Reset</button> -->
            </div>

            <div class="select">
              <label
                v-for="filter in filters"
                :key="filter.id">
                <!-- @click="clickFilter(valueFilter, filter)" -->
                <input
                  type="checkbox"
                  @click="clickFilters(filter)"
                  v-model="valueFilters"
                  :value="filter">
                <div class="checkmark"></div>
                <span>{{ filter.name.toUpperCase() }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-9 col-xl-9">
        <div class="section">
          <div class="row intro">
            <div class="col-12 col-md-4 title">
              <TitleHead title="Menu" />
            </div>

            <div class="col-12 col-md-8 content">
              <p>'Go live' your day, with the beverage of your choice. Try it!</p>
            </div>

            <div class="col-12 filter-m">
              <span>Beverage and drinking</span>
              <select
                v-model="valueCategory"
                class="categoryMenu" >
                <option
                  v-for="category in categories"
                  :key="category.id"
                  :value="category"
                  :data-value="category.name" >
                  {{ category.name }}
                </option>
              </select>

              <select v-model="valueFilterMobile">
                <option
                  v-for="filter in filters"
                  :key="filter.id"
                  :value="filter">
                  {{ filter.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <section class="products">
          <div v-if="menus.length !== 0">
            <div
              v-for="(menu, i) in menus"
              :key="i">
              <div v-if="menu.data.length !== 0">
                <div class="head">
                  <h3>{{ menu.category }}</h3>
                </div>
                <div class="row box-card">
                  <div
                    v-for="(cardMenu, i) in menu.data"
                    :key="i"
                    class="col-12 col-md-6 col-lg-4 col-xl-3">
                    <CardMenu
                      :data="cardMenu"
                      class="card"
                      :style="{ animationDelay: `${ (i + 1) * 100 }ms`  }" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import head from './head'
import { mapState } from 'vuex'
import url from '@/resources/_url'

const Loading = () => import('@/components/Loading')
const TitleHead = () => import('@/components/TitleHead')
const CardMenu = () => import('@/components/CardMenu')

export default {
  components: {
    Loading,
    TitleHead,
    CardMenu
  },

  head: head,

  data () {
    return {
      categories: [],
      categoriesId: [],
      filters: [],
      cateAndFilter: {
        category: '0',
        filter: [{
          id: 0,
          name: 'All'
        }]
      },
      valueCategory: {
        id: 0,
        name: 'All'
      },
      passCategory: false,
      valueFilterMobile: null,
      valueFilters: [],
      menus: [],
      passChangeFilter: true,
      hasMenusData: false,
      noChangeFilterData: false
    }
  },

  computed: {
    ...mapState({
      isLoadCate: state => state.menu.isLoadCate,
      isLoadFilter: state => state.menu.isLoadFilter,
      isLoadMenu: state => state.menu.isLoadMenu,
      hadCate: state => state.menu.hadCate,
      hadFilter: state => state.menu.hadFilter,
      hadMenu: state => state.menu.hadMenu,
      rawCategories: state => state.menu.rawCategories,
      rawFilters: state => state.menu.rawFilters,
      rawMenus: state => state.menu.rawMenus
    })
  },

  methods: {
    checkHasData () {
      if (this.hadCate &&
          this.hadFilter &&
          this.hadMenu) {
        if (!this.hasMenusData) {
          this.hasMenusData = true
        }
      }
    },

    mapFilterMenu (menus, filters) {
      if (menus && this.hadMenu) {
        this.filters = []
        let arr = []

        filters.forEach(filter => {
          menus.forEach(menus => {
            menus.data.forEach(menu => {
              if (menu.filter === filter.id.toString()) {
                arr.push(filter)
              }

              if (menu.other.length !== 0) {
                menu.other.forEach(menuOther => {
                  if (menuOther.filter.toString() === filter.id.toString()) {
                    arr.push(filter)
                  }
                })
              }
            })
          })
        })

        let newArr = [...new Set(arr)]
        newArr.unshift(filters[0])
        this.filters = newArr

        this.passChangeFilter = false
      }
    },

    mapMenus (menus, category) {
      if (menus !== null && this.hadCate) {
        this.menus = []

        if (category.id === 0) {
          this.rawCategories.forEach(category => {
            let objMain = {
              category: category.name,
              data: []
            }

            menus.forEach(menu => {
              if (
                menu.category.toString() === category.id.toString() ||
                menu.category === category.name
              ) {
                let objData = {
                  id: menu.id,
                  name: menu.name,
                  // price: `฿${menu.price}`,
                  price: new Intl.NumberFormat({ style: 'currency', currency: 'THB' }).format(menu.price) + '฿',
                  category: menu.category,
                  filter: menu.filter,
                  imgUrl: menu.image ? menu.image : `${url.menuImg}/${menu.img_main}`,
                  other: []
                }

                if (menu.img_hover.length > 0) {
                  menu.img_hover.forEach(hover => {
                    let objHover = {
                      id: hover.id,
                      name: hover.name,
                      price: new Intl.NumberFormat({ style: 'currency', currency: 'THB' }).format(hover.price) + '฿',
                      category: hover.category,
                      filter: hover.filter,
                      imgUrl: hover.image ? hover.image : `${url.menuImg}/${hover.img_main}`
                    }

                    objData.other.push(objHover)
                  })
                }

                objMain.data.push(objData)
              }
            })
            this.menus.push(objMain)
          })
        } else {
          let objMain = {
            category: category.name,
            data: []
          }

          menus.forEach(menu => {
            if (
              menu.category.toString() === category.id.toString() ||
              menu.category === category.name
            ) {
              let objData = {
                id: menu.id,
                name: menu.name,
                price: `฿${menu.price}`,
                category: menu.category,
                filter: menu.filter,
                imgUrl: `${url.menuImg}/${menu.img_main}`,
                other: []
              }

              if (menu.img_hover.length > 0) {
                menu.img_hover.forEach(hover => {
                  let objHover = {
                    id: hover.id,
                    name: hover.name,
                    price: `฿${hover.price}`,
                    category: hover.category,
                    filter: hover.filter,
                    imgUrl: hover.image ? hover.image : `${url.menuImg}/${hover.img_main}`
                  }

                  objData.other.push(objHover)
                })
              }
              objMain.data.push(objData)
            }
          })

          this.menus.push(objMain)
        }
      }
    },

    async clickFilters (filters) {
      setTimeout(() => {
        if (filters.id.toString() === '0') {
          this.valueFilters = []
          this.valueFilters.push(this.rawFilters[0])
        } else {
          if (this.valueFilters.length === 0) {
            this.valueFilters.push(this.rawFilters[0])
          } else {
            this.valueFilters.forEach(filter => {
              if (filter.id.toString() === '0') {
                let index = this.valueFilters.findIndex(x => x.id.toString() === '0')
                this.valueFilters.splice(index, 1)
              }
            })
          }
        }
      }, 100)
    }
  },

  watch: {
    rawCategories (categories) {
      if (!this.valueFilters.includes(filter => filter.id.toString() === 0)) {
        this.categories = categories
        this.cateAndFilter.category = this.rawCategories[0].id
      }

      categories.forEach(category => {
        let checkAll = category.name.toUpperCase() === 'ALL'
        let checkRTD = category.name.toUpperCase() === 'RTD(READY TO DRINK)'

        if (checkAll) {
          this.categoriesId.push('')
        }

        if (checkRTD) {
          this.categoriesId.push('readytodrinkMenu')
        }

        if (!checkAll && !checkRTD) {
          this.categoriesId.push(`${category.name.toLowerCase()}Menu`)
        }
      })
    },

    rawFilters (filters) {
      this.valueFilters[0] = filters[0]
      this.valueFilterMobile = filters[0]

      this.cateAndFilter.filter.push(this.rawFilters[0].id)
    },

    rawMenus (menus) {
      if (this.hasMenusData) {
        this.mapMenus(menus, this.valueCategory)
      }
    },

    hadCate () {
      this.checkHasData()
    },

    hadFilter () {
      this.checkHasData()
    },

    hadMenu () {
      this.checkHasData()
    },

    hasMenusData () {
      this.mapMenus(this.rawMenus, this.valueCategory)
    },

    filters (data) {
      if (data.length > 0 && !this.noChangeFilterData) {
        this.noChangeFilterData = true
      }
    },

    menus (menus) {
      if (!this.noChangeFilterData) {
        this.mapFilterMenu(menus, this.rawFilters)
      }
    },

    async valueCategory (category) {
      this.passChangeFilter = true
      this.noChangeFilterData = false
      this.cateAndFilter.category = await category.id
      this.cateAndFilter.filter = await []
      await this.cateAndFilter.filter.push(this.rawFilters[0].id)

      this.valueFilters = await []
      await this.valueFilters.push(this.rawFilters[0])

      this.$store.dispatch('menu/getMenu', this.cateAndFilter)
    },

    async valueFilterMobile (filter) {
      this.cateAndFilter.filter = await [filter]

      await this.$store.dispatch('menu/getMenu', this.cateAndFilter)
    },

    valueFilters () {
      this.cateAndFilter.filter = this.valueFilters
      this.$store.dispatch('menu/getMenu', this.cateAndFilter)
    }
  },

  created () {
    this.$store.dispatch('menu/getCategory')
    this.$store.dispatch('menu/getFilter')
    this.$store.dispatch('menu/getMenu', this.cateAndFilter)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

.menu {
  padding-top: 60px;

  .col-filter {
    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: center;
    }

    .filter-left {
      overflow: scroll;
      overflow-x: hidden;
      user-select: none;
      display: none;
      position: fixed;
      margin-top: 80px;
      padding: 0 22px;
      height: calc(100vh - (60px + 100px));

      @media screen and (min-width: 768px) {
        display: block;
      }

      .category {
        display: flex;
        flex-direction: column;

        label {
          cursor: pointer;
          display: flex;
          margin: 5px 0;
          width: fit-content;

          input {
            opacity: 0;
          }

          .text {
            margin-left: -14px;

            span {
              font-size: 14px;
            }

            .selected-line {
              margin-top: -4px;
              width: 0;
              height: 1px;
              background-color: $color-main;
              transition: 800ms $ease;
            }
          }

          input:checked ~ .text span {
            font-family: 'DB Ozone X Bd';
          }

          input:checked ~ .text .selected-line {
            width: 100%;
          }
        }

        label:first-child {
          margin-top: 0;
        }

        label:hover {
          .text {
            .selected-line {
              width: 30%;
            }
          }
        }
      }

      hr {
        margin: 15px 0;
        border: 2px solid #CCC;
      }

      .filter {
        .option {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0;

          span {
            font-family: 'DB Ozone X Bd';
          }

          button {
            border: none;
            background: none;
            font-family: 'DB Ozone X Med';
            color: #999;
          }

          button:hover {
            color: black;
          }
        }
      }

      .select {
        display: flex;
        flex-direction: column;

        label {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin: 5px 0;
          width: fit-content;

          .checkmark {
            margin-left: -14px;
            margin-right: 12px;
            height: 18px;
            width: 18px;
            border-radius: 4px;
            border: 1px solid black;
            // background-color: white;
            transition: 800ms $ease;
          }

          .checkmark:after {
            display: none;
            margin: 3px 0 0 7px;
            width: 5px;
            height: 10px;
            content: '';
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }

          input {
            opacity: 0;
          }

          input:checked ~ .checkmark {
            border: none;
            background-color: #0098F7;
          }

          input:checked ~ .checkmark:after {
            display: block;
          }
        }

        label:first-child {
          margin-top: 0;
        }
      }
    }

    .filter-left::-webkit-scrollbar {
      width: 3px;
    }

    .filter-left::-webkit-scrollbar-track {
      background-color: white;
      border-radius: 10px;
    }

    .filter-left::-webkit-scrollbar-thumb {
      background-color: #EEE;
      border-radius: 3px;
    }
  }

  .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 20px 0;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    .title {
      display: flex;
      justify-content: center;

      @media screen and (min-width: 768px) {
        justify-content: flex-start;
      }
    }

    .filter-m {
      display: flex;
      flex-direction: column;
      margin-top: 60px;

      @media screen and (min-width: 768px) {
        display: none;
      }

      span {
        font-family: 'DB Ozone X Bd';
        color: #333;
      }

      select {
        outline: none;
        margin-bottom: 25px;
        padding: 10px 15px;
        border-radius: 0;
        border: 1px solid #CCC;
        background-color: white;
        background-image: url('../../assets/icons/down-arrow.svg');
        background-repeat: no-repeat;
        background-position: calc(100% - 15px) center;
        background-size: auto 8px;
        font-size: 18px;
      }

      .filter {
        .option {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0;

          span {
            font-family: 'DB Ozone X Bd';
          }

          button {
            border: none;
            background: none;
            font-family: 'DB Ozone X Med';
            color: #999;
          }

          button:hover {
            color: black;
          }
        }

        .select {
          display: flex;
          flex-direction: column;

          label {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin: 10px 0;
            width: fit-content;

            .checkmark {
              margin-left: -14px;
              margin-right: 12px;
              height: 18px;
              width: 18px;
              border-radius: 4px;
              border: 1px solid black;
              background-color: white;
              transition: 800ms $ease;
            }

            .checkmark:after {
              display: none;
              margin: 3px 0 0 7px;
              width: 5px;
              height: 10px;
              content: '';
              border: solid white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }

            input {
              opacity: 0;
            }

            input:checked ~ .checkmark {
              border: none;
              background-color: #0098F7;
            }

            input:checked ~ .checkmark:after {
              display: block;
            }
          }

          label:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.products {
  padding-bottom: 30px;

  @media screen and (min-width: 768px) {
    padding-bottom: 0;
  }

  .head {
    padding: 10px 0;
    width: 100%;
    border: solid;
    border-width: 1px 0 1px 0;
    border-color: #CCC;

    h3 {
      font-family: 'DB Ozone X Bd';
    }
  }

  .box-card {
    padding: 15px 0;

    @media screen and (min-width: 768px) {
      padding: 45px 0;
    }

    .card {
      opacity: 0;
      transform: translateY(-80px);
      animation: card 800ms $ease forwards;
    }

    @keyframes card {
      from {
        opacity: 0;
        transform: translateY(-80px);
      }

      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .empty {
    opacity: 0;
    margin-top: 30px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(40vh - (60px + 124px + 203px));
    border-radius: 20px;
    border: 1px dashed black;
    animation: empty 800ms $ease 1000ms forwards;

    @media screen and (min-width: 768px) {
      height: calc(100vh - (60px + 203px));
    }

    @keyframes empty {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 0.3;
      }
    }
  }
}
</style>
